import React, { useState, useRef, useEffect } from "react";
import "./Landingpage.css";
import img1 from "../../../assets/Images/cabinate15.avif";
import img2 from "../../../assets/Images/cabinate16.jpeg";
import img3 from "../../../assets/Images/cabinate17.webp";
import img4 from "../../../assets/Images/cabinate18.jpg";
import musicFile from "../Landingpage/images/music.mp3";

const sliderData = [  
  {
    img: img1,
    title: "MAGIC SLIDER",
    type: "FLOWER",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti temporibus quis eum consequuntur voluptate quae doloribus distinctio. Possimus, sed recusandae.",
  },
  {
    img: img2,
    title: "MAGIC SLIDER",
    type: "NATURE",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti temporibus quis eum consequuntur voluptate quae doloribus distinctio. Possimus, sed recusandae.",
  },
  {
    img: img3,
    title: "MAGIC SLIDER",
    type: "PLANT",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti temporibus quis eum consequuntur voluptate quae doloribus distinctio. Possimus, sed recusandae.",
  },
  {
    img: img4,
    title: "MAGIC SLIDER",
    type: "NATURE",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti temporibus quis eum consequuntur voluptate quae doloribus distinctio. Possimus, sed recusandae.",
  },
];

const Slider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMusicOn, setIsMusicOn] = useState(true); // Music starts as ON
  const audioRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio && isMusicOn) {
      // Play the music if it's turned on
      audio
        .play()
        .catch((err) => console.error("Error playing audio:", err));
    } else if (audio) {
      // Pause the music if it's turned off
      audio.pause();
    }
  }, [isMusicOn]); // Sync with isMusicOn state

  const handleMoveSlider = (dir) => {
    if (dir === "next") {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % sliderData.length);
    } else {
      setCurrentIndex(
        (prevIndex) =>
          (prevIndex - 1 + sliderData.length) % sliderData.length
      );
    }
  };

  const toggleMusic = () => {
    setIsMusicOn((prev) => !prev);
  };

  return (
    <div className="slider w-full">
      {/* Background Music */}
      <audio ref={audioRef} loop>
        <source src={musicFile} type="audio/mp3" />
      </audio>

      {/* Slider Content */}
      <div className="list">
        <div
          className="item"
          style={{
            backgroundImage: `url(${sliderData[currentIndex].img})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="content">
            <div className="title">{sliderData[currentIndex].title}</div>
            <div className="type">{sliderData[currentIndex].type}</div>
            <div className="description">
              {sliderData[currentIndex].description}
            </div>
            <div className="button">
              <button>SEE MORE</button>
            </div>
          </div>
        </div>
      </div>

      {/* Thumbnails */}
      <div className="thumbnail">
        {sliderData.map((slide, index) => (
          <div
            key={index}
            className={`item ${
              index === currentIndex ? "active-thumbnail" : ""
            }`}
            onClick={() => setCurrentIndex(index)}
          >
            <img src={slide.img} alt={`Thumbnail ${index + 1}`} />
          </div>
        ))}
      </div>

      {/* Navigation Arrows */}
      <div className="nextPrevArrows">
        <button className="prev" onClick={() => handleMoveSlider("prev")}>
          {"<"}
        </button>
        <button className="next" onClick={() => handleMoveSlider("next")}>
          {">"}
        </button>
      </div>

      {/* Music Toggle */}
      <div className="music-toggle" onClick={toggleMusic}>
        <div className={`music-icon ${isMusicOn ? "waiving" : ""}`}>
          🎵
        </div>
      </div>
    </div>
  );
};

export default Slider;
