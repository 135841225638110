import React, { useState } from "react";
import "./contact.css";
import { div } from "framer-motion/client";

const Contact = () => {
  const [isActive, setIsActive] = useState(false);
    
  return (
    <>
   <div className="flex items-center justify-center bg-[#ffe0b2] h-screen">
     <div className={`container ${isActive ? 'active' : ''} `} id="container">
      <div className="form-container sign-up">
        <form>
          <h1 className="text-2xl font-bold text-black" >Create Account</h1> 
          <span className="text-lg font-bold text-black">or use your email for registration</span>
          <input type="text" className="text-black" placeholder="Name" />
          <input type="email" className="text-black"  placeholder="Email" />
          <input type="password" className="text-black"  placeholder="Password" />
          <button type="button"    >Sign Up</button>
        </form>
      </div>

      <div className="form-container sign-in">
        <form>
          <h1 className="text-2xl font-bold text-black">Sign In</h1>
         
          <span className="text-2xl font-semibold text-black"> use your email password</span>
          <input className="text-black" type="email" placeholder="Email" />
          <input className="text-black" type="password" placeholder="Password" />
          {/* <a href="#">Forget Your Password?</a> */}
          <button type="button">
          <a href="/">
            Sign In
          </a>
          </button>
        </form>
      </div>

      <div className="toggle-container">
        <div className="toggle">
          <div className="toggle-panel toggle-left">
            <h1 className="text-2xl font-semibold">Welcome Back!</h1>
            <p className="text-lg font-semibold">Enter your personal details to use all site features</p>
            <button
              className=""
              id="login"
              onClick={() => setIsActive(false)}
            >
              Sign In
            </button>
          </div>
          <div className="toggle-panel toggle-right">
            <h1 className="text-2xl font-semibold">Hello, Friend!</h1>
            <p className="text-lg font-semibold">Register with your personal details to use all site features</p>
            <button
              className=""
              id="register"
              onClick={() => setIsActive(true)}
            >
              Sign Up
            </button>
          </div>
        </div>
      </div>
    </div>
   </div>
   </>
  );
};

export default Contact;
